// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-developments-tsx": () => import("./../../../src/pages/developments.tsx" /* webpackChunkName: "component---src-pages-developments-tsx" */),
  "component---src-pages-graphics-tsx": () => import("./../../../src/pages/graphics.tsx" /* webpackChunkName: "component---src-pages-graphics-tsx" */),
  "component---src-pages-hobbies-tsx": () => import("./../../../src/pages/hobbies.tsx" /* webpackChunkName: "component---src-pages-hobbies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-others-tsx": () => import("./../../../src/pages/others.tsx" /* webpackChunkName: "component---src-pages-others-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-common-post-template-tsx": () => import("./../../../src/templates/CommonPostTemplate.tsx" /* webpackChunkName: "component---src-templates-common-post-template-tsx" */)
}

